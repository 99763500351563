@import "src/app/styles/common";

.native-ios {
    .page-critical-error {
        height: 95dvh;
        margin-top: env(safe-area-inset-top);
        margin-bottom: env(safe-area-inset-bottom);

        @include xlm {
            height: 85dvh;
        }
        @include xm {
            height: 95dvh;
        }
    }
}
