@import "src/app/styles/common";

.modal {
    display: flex;
    flex-direction: column;
    font-family: var(--ff-secondary);
    width: rem(600);

    @include lg {
        width: rem(400);
    }

    @include xlm {
        width: 100%;
    }

    &Header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(15);
        margin-bottom: rem(20);
        padding: rem(10) 0;

        @include sm {
            gap: rem(9);
            margin-bottom: rem(10);
        }

        @include xlm {
            margin-bottom: 0;
        }
    }

    &Title {
        text-transform: uppercase;
        font-size: rem(60);
        line-height: 1;
        color: var(--clr-success);

        @include sm {
            font-size: rem(50);
        }

        @include xlm {
            font-size: rem(45);
        }

        @include xm {
            font-size: rem(40);
        }
    }

    &Text {
        font-size: rem(40);
        line-height: 1;
        text-align: center;

        @include sm {
            font-size: rem(35);
        }

        @include xm {
            font-size: rem(30);
        }

        span {
            color: var(--clr-primary);
        }
    }

    &Icon {
        width: rem(45);
        height: rem(45);

        @include before-sm {
            width: rem(68);
            height: rem(68);
            transform: translateY(calc(rem(4) * -1));
        }
    }
}
