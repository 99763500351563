.paper-ui {
    /* padding: 10px; */
    /* background-color: #111; */
}

.paper-ui.paper-outlined {
    /* border: 1px solid #EEE; */
}

.paper-ui.paper-elevation.paper-elevation0 {
    -webkit-box-shadow: 0 5px 5px #00000000;
    box-shadow: 0 5px 5px #00000000;
}

.paper-ui.paper-elevation.paper-elevation1 {
    -webkit-box-shadow: 0 5px 5px #00000020;
    box-shadow: 0 5px 5px #00000020;
}

.paper-ui.paper-elevation.paper-elevation2 {
    -webkit-box-shadow: 0 5px 5px #00000040;
    box-shadow: 0 5px 5px #00000040;
}

.paper-ui.paper-elevation.paper-elevation3 {
    -webkit-box-shadow: 0 5px 5px #00000060;
    box-shadow: 0 5px 5px #00000060;
}

.paper-ui.paper-elevation.paper-elevation4 {
    -webkit-box-shadow: 0 5px 5px #00000080;
    box-shadow: 0 5px 5px #00000080;
}

.paper-ui.paper-elevation.paper-elevation5 {
    -webkit-box-shadow: 0 5px 5px #000000a0;
    box-shadow: 0 5px 5px #000000a0;
}

.paper-ui.paper-elevation.paper-elevation6 {
    -webkit-box-shadow: 0 5px 5px #000000c0;
    box-shadow: 0 5px 5px #000000c0;
}

.paper-ui.paper-elevation.paper-elevation7 {
    -webkit-box-shadow: 0 5px 5px #000000e0;
    box-shadow: 0 5px 5px #000000e0;
}

.paper-ui.paper-elevation.paper-elevation8 {
    -webkit-box-shadow: 0 5px 5px #000000ff;
    box-shadow: 0 5px 5px #000000ff;
}
