@import "src/app/styles/common";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
    background-color: var(--clr-background-primary);
}

.row {
    align-self: flex-end;
    width: 100%;
    height: 20px;
    background-color: var(--clr-primary);
}

.icon {
    width: 100px;
}

.logo {
    width: 260px;
    height: 60px;
}

.content {
    color: var(--clr-text-primary);
    text-align: center;
    padding: 0 30px;
    font-family: var(--ff-secondary);

    &Top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }

    &Middle {
        padding: 15px 0;
        margin-bottom: 15px;
        border-top: 3px solid var(--clr-border-primary);
        border-bottom: 3px solid var(--clr-border-primary);
    }

    &Bottom {
    }
}

.smallText {
    font-size: 30px;
    text-transform: uppercase;
    line-height: 30px;
    max-width: 320px;
    text-align: center;
    margin: 0 auto;
}

.bottomText30 {
    font-size: 30px;
    line-height: 40px;
}

.bottomText50 {
    font-size: 50px;
    line-height: 66px;
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
    span {
        color: var(--clr-primary);
        cursor: pointer;
    }
}
