$clr-primary: #fff;
$clr-background-primary: #000000;

$accent-color: #f7c521;
$clr-text-primary: #ffffff;
$clr-text-secondary: #0a0a0a;

$clr-assist-primary: #ffffff;
$clr-assist-secondary: #000000;
$clr-success: #06cb06;
$clr-danger: #ff0a0a;
$clr-blue: #408eed;
$clr-purple: #da1695;

:root {
    --clr-primary: #{$clr-primary};
    --clr-primary66: #{transparentize($clr-primary, 0.66)};
    --clr-primary10: #{transparentize($clr-primary, 0.1)};

    --clr-accent: #{$accent-color};
    --clr-accent10: #{transparentize($accent-color, 0.1)};
    --clr-accent66: #{transparentize($accent-color, 0.66)};
    --clr-header-shape: #{$clr-primary};
    --clr-secondary: color-mix(in oklab, #{$clr-assist-secondary} 80%, #{$clr-assist-primary});
    --clr-sidepanel: color-mix(in oklab, #{$clr-assist-secondary} 74%, #{$clr-assist-primary});

    --clr-text-primary: #{$clr-text-primary};
    --clr-text-primary50: #{transparentize($clr-text-primary, 0.5)};
    --clr-text-primary01: color-mix(in oklab, #{$clr-text-primary} 60%, #{$clr-assist-secondary});
    --clr-text-primary02: color-mix(in oklab, #{$clr-text-primary} 90%, #{$clr-assist-secondary});
    --clr-text-secondary: #{$clr-text-secondary};

    --clr-border-primary: color-mix(in oklab, #{$clr-assist-secondary} 50%, #{$clr-assist-primary});
    --clr-border75: color-mix(in oklab, #{$clr-assist-secondary} 67%, #{$clr-assist-primary});

    --clr-gray01: color-mix(in oklab, #{$clr-assist-secondary} 60%, #{$clr-assist-primary});
    --clr-gray02: color-mix(in oklab, #{$clr-assist-secondary} 35%, #{$clr-assist-primary});
    --clr-gray03: color-mix(in oklab, #{$clr-assist-secondary} 69%, #{$clr-assist-primary});
    --clr-gray04: color-mix(in oklab, #{$clr-assist-secondary} 70%, #{$clr-assist-primary});
    --clr-gray05: color-mix(in oklab, #{$clr-assist-secondary} 58%, #{$clr-assist-primary});

    --clr-background-modal: #{transparentize($clr-assist-primary, 0.5)};
    --clr-background-primary: #{$clr-background-primary};
    --clr-background-primary50: #{transparentize($clr-background-primary, 0.5)};

    --clr-light: #{$clr-assist-primary};
    --clr-light50: #{transparentize($clr-assist-primary, 0.5)};
    --clr-light80: #{transparentize($clr-assist-primary, 0.8)};
    --clr-light90: #{transparentize($clr-assist-primary, 0.9)};
    --clr-dark: #{$clr-assist-secondary};
    --clr-dark50: #{transparentize($clr-assist-secondary, 0.5)};
    --clr-dark80: #{transparentize($clr-assist-secondary, 0.8)};

    --clr-success: #{$clr-success};
    --clr-success40: #{transparentize($clr-success, 0.4)};

    --clr-danger: #{$clr-danger};
    --clr-danger02: color-mix(in oklab, #{$clr-danger} 80%, #{$clr-assist-secondary});
    --clr-danger03: color-mix(in oklab, #{$clr-danger} 85%, #{$clr-assist-primary});
    --clr-danger20: #{transparentize($clr-danger, 0.2)};

    --clr-blue: #{$clr-blue};
    --clr-blue02: color-mix(in oklab, #{$clr-blue} 90%, #{$clr-assist-secondary});

    --clr-purple: #{$clr-purple};

    --swiper-theme-color: #{var(--clr-primary)} !important;
}
