.paper-ui {
    border-radius: 15px;
    padding: 20px;
    background-color: #111;

    &.paper-outlined {
        border: 1px solid #eee;
    }

    &.paper-elevation {
        &.paper-elevation0 {
            box-shadow: 0 5px 5px #00000000;
        }

        &.paper-elevation1 {
            box-shadow: 0 5px 5px #00000020;
        }

        &.paper-elevation2 {
            box-shadow: 0 5px 5px #00000040;
        }

        &.paper-elevation3 {
            box-shadow: 0 5px 5px #00000060;
        }

        &.paper-elevation4 {
            box-shadow: 0 5px 5px #00000080;
        }

        &.paper-elevation5 {
            box-shadow: 0 5px 5px #000000a0;
        }

        &.paper-elevation6 {
            box-shadow: 0 5px 5px #000000c0;
        }

        &.paper-elevation7 {
            box-shadow: 0 5px 5px #000000e0;
        }

        &.paper-elevation8 {
            box-shadow: 0 5px 5px #000000ff;
        }
    }
}
