@import "src/app/styles/common";

.notification {
    display: flex;
    position: fixed;
    left: 50%;
    bottom: rem(20);
    z-index: 999;
    margin: rem(5) 0;
    min-height: rem(38);
    max-width: rem(420);

    overflow: hidden;
    font-family: var(--ff-primary);
    background-color: var(--clr-sidepanel);
    box-shadow: 0 rem(1) rem(2) var(--clr-background-primary);
    border-radius: rem(10);
    transform: translateX(500%);
    opacity: 0;
    transition: all 0.5s ease-out;

    &.active {
        transform: translateX(-50%);
        opacity: 1;
    }

    @include xlm {
        bottom: rem(10);
        min-width: rem(280);
        width: calc(100% - rem(50));
    }

    &::before {
        content: "";
        height: 100%;
        width: rem(10);
        background-color: var(--clr-primary);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }

    &Inner {
        flex: 0 1 90%;
        padding: rem(12) rem(25);
        margin-right: rem(35);

        display: flex;
        align-items: center;
        gap: rem(10);
    }

    &Text {
        color: var(--clr-text-primary);
        font-size: rem(16);
        font-weight: 500;
        text-transform: none;
        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
        @include xlm {
            font-size: rem(15);
        }
    }

    &CloseWrapper {
        width: rem(28);
        height: rem(28);
        padding: rem(3) rem(8);

        position: absolute;
        right: rem(5);
        top: rem(5);
        z-index: 5;

        transition: $defaultTransition;
        border-radius: 50%;
        cursor: pointer;

        background-color: var(--clr-light90);
        @include xlm {
            padding: rem(1) rem(7);
        }
    }

    &CloseIcon {
        display: block;
        width: rem(12);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
            fill: var(--clr-light);
        }

        @include xlm {
            width: rem(10);
        }
    }
}

.icon {
    flex: 0 0 rem(60);
    width: rem(60);

    @include xlm {
        flex: 0 0 rem(40);
        width: rem(40);
    }
}
