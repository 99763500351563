@import "src/app/styles/common";

.modal-ui-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-modal);
}

.product-modal-item {
    > div {
        position: relative;
        .product-container {
            display: flex;
            align-items: stretch;
            gap: 1em;
            .product-info-container {
                gap: 1.5em;
            }
            .responsive-flex {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .product-option-value {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 1em 0.2em 1em;
                line-height: 1;
                height: 1.5em;
                box-sizing: border-box;
            }
            .quantity-ui {
                .quantity-minus {
                    padding: 0.7rem 1rem 0.7rem 1.2rem;
                }
                .quantity-ui .quantity-value {
                    padding: 0.7rem 1.4rem;
                }
                .quantity-ui .quantity-plus {
                    padding: 0.7rem 1.2rem 0.7rem 1rem;
                }
            }
            button.button-ui.button-contained.product-button-buy-it-now {
                background: var(--clr-primary);
                font-weight: 600;
                color: var(--clr-text-secondary);
                outline: none;
                padding: 0.8em 1em;
            }
        }
        .dialog-close-button {
            position: absolute;
            top: rem(20);
            right: rem(30);
            width: rem(40);
            height: rem(40);
            cursor: pointer;

            svg {
                width: rem(22);
                height: rem(22);
                stroke-width: 2;
                path {
                    fill: var(--clr-light);
                }
            }
        }
    }
}
@include lg {
    .product-modal-item {
        max-height: 100%;
        overflow: hidden;
        > div {
            position: relative;
            padding: 1em;
            overflow: hidden;
            .product-container {
                display: flex;
                align-items: flex-start;
                overflow-y: auto;
                height: 100%;
                .product-image-container {
                    width: 100%;
                    .product-image {
                        width: 100%;
                    }
                }
                .responsive-flex {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: flex-end;
                }
                .product-info-container {
                    gap: 0;
                    .product-quantity-container {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5em;
                        align-items: flex-start;
                        margin-top: 0.7em;
                    }
                    .product-option-value {
                        padding: 0.1em 0.5em;
                    }
                }
                .product-buttons-container {
                    width: fit-content;
                    min-width: 130px;
                    margin-left: 0.5em;
                    button.button-ui.button-contained.product-button-buy-it-now {
                        padding: 1em;
                    }
                }
            }
            .dialog-close-button {
                top: rem(40);
                right: rem(40);
            }
        }
    }
}

.product-modal-item > div .dialog-close-button {
    @include xl {
        top: rem(30);
        right: rem(30);
    }

    @include sm {
        top: rem(25);
        right: rem(25);
    }
}

.native-ios {
    .modal-ui-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--z-index-modal);
        top: env(safe-area-inset-top);
    }
}
