@import "src/app/styles/common";

.overlay {
    height: 100%;
    width: 100%;
    padding: 0 rem(10);
    display: none;
    align-items: center;
    justify-content: center;
    transition: $defaultTransition;
    background-color: var(--clr-background-modal);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-modal);

    &Shown {
        display: flex;
        animation: openModal 0.25s;
    }
}

.ffPrimary {
    font-family: var(--ff-primary);
    --modal-fz: #{rem(35)};

    @include sm {
        --modal-fz: #{rem(30)};
    }

    button {
        --btn-fz: 0.7em;
    }
}

.ffSecondary {
    font-family: var(--ff-secondary);
    --modal-fz: #{rem(40)};

    @include sm {
        --modal-fz: #{rem(35)};
    }

    button {
        --btn-fz: 0.75em;
        --btn-fw: 500;
        --btn-ff: inherit;
        padding: 0.25em 0.5em;
    }
}

.modal {
    display: flex;
    flex-direction: column;
    max-height: 90%;
    position: relative;

    font-size: var(--modal-fz);
    color: var(--clr-text-primary);
    border-radius: 0.9em;
    padding: 0.5em 1em;
    background-color: var(--clr-background-primary);

    @include xlm {
        width: 100%;
    }
}

.closeBtnWrapper {
    position: absolute;
    top: 0.35em;
    right: 0.35em;

    width: 1em;
    height: 1em;
    padding: 0.25em;
    cursor: pointer;

    &:hover {
        path {
            fill: var(--clr-primary);
        }
    }
}

.closeBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--clr-text-primary);
    transition: $defaultTransition;
    width: 2em;
    height: 2em;
    cursor: pointer;
    &Icon {
        cursor: pointer;
        border-radius: 0.3em;
        width: 1.5em;
        stroke-width: 2;
        stroke-linecap: round;
        path {
            fill: var(--clr-light);
        }
    }
}

.header {
    position: relative;
    padding: 0.5em;
}

.headerTitle {
    color: var(--clr-text-primary);
    font-family: inherit;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
}

.body {
    flex-grow: 1;
    font-family: inherit;
    color: var(--clr-text-primary);

    display: flex;
    flex-direction: column;
    padding: 0.25em 0;
}

.footer {
    display: flex;
    justify-content: center;
    gap: 0.35em;
    padding: 0.5em 0;
    font-family: inherit;

    button {
        font-family: inherit;
        --btn-min-width: auto;
        --btn-min-height: auto;
        width: 100%;
        max-width: rem(250);
    }
}

.hasScroll {
    overflow-y: auto;
}

@keyframes openModal {
    0% {
        display: flex;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
