.dialog-ui.modal-ui-container {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 0.2s;

    .paper-ui {
        transition: opacity 0.5s;
    }

    &.dialog-ui-opened {
        background-color: var(--clr-background-modal);
    }
    &.dialog-ui-hidden {
        background-color: transparent;
        pointer-events: none;

        .paper-ui {
            opacity: 0;
        }
    }
}
