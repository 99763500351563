@font-face {
    font-family: "Be Vietnam Pro Semibold";
    src: url("../fonts/BeVietnamPro-SemiBold.ttf");
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Anton";
    src: url("../fonts/Anton-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Bebas Neue";
    src: url("../fonts/BebasNeue-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}
