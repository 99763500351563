@import "src/app/styles/common";

.wrapper {
    --auth-modal-width: #{rem(575)};

    font-family: var(--ff-secondary);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: var(--auth-modal-width);
    padding: rem(20) rem(25) rem(60);
    background-color: var(--clr-background-primary);
    border-radius: rem(60);

    @include xl {
        max-height: 90vh;
        overflow-y: auto;
        padding: rem(20) rem(25) rem(40);
        border-radius: rem(40);
    }

    @include sm {
        border-radius: rem(30);
        --auth-modal-width: #{rem(500)};
    }
    @include xlm {
        --auth-modal-width: #{rem(380)};
        padding: rem(20) rem(15) rem(25);
    }
    @include iphoneProMax {
        --auth-modal-width: #{rem(400)};
    }
    @include xm {
        --auth-modal-width: #{rem(330)};
    }
    @include iphonePro {
        --auth-modal-width: #{rem(350)};
    }
    @include iphone14 {
        --auth-modal-width: #{rem(350)};
    }
    @media (orientation: landscape) and (max-width: map_get($MEDIA, md)) {
        --auth-modal-width: #{rem(610)};
    }
}

.logo {
    width: rem(400);

    @include xl {
        width: rem(350);
    }
    @include sm {
        width: rem(250);
    }

    path {
        fill: var(--clr-primary);
    }
}

.content {
    color: var(--clr-text-primary);
    align-self: stretch;
    text-transform: uppercase;
    text-align: center;
    margin-top: rem(8);
}

.title {
    font-size: rem(55);
    margin: 0 auto rem(5);
    line-height: 1;
    @include xl {
        font-size: rem(45);
    }
    @include sm {
        font-size: rem(35);
    }
}

.text {
    font-size: rem(25);
    font-weight: 600;
    color: var(--clr-sidepanel);
    line-height: 1;
    font-family: var(--ff-primary);
    @include xl {
        font-size: rem(20);
    }
    @include sm {
        font-size: rem(16);
    }
    @include xm {
        font-size: rem(14);
    }
}

.awesomeness {
    @extend .title;
    color: var(--clr-text-primary);
    font-size: rem(80);
    @include xl {
        font-size: rem(70);
    }
    @include sm {
        font-size: rem(60);
    }
    @include xlm {
        font-size: rem(45);
    }
}

.signIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(15);
    margin-top: rem(15);
    border-radius: rem(30);
    padding: rem(40) 0 rem(45);
    border: rem(2) solid var(--clr-primary);

    @include xl {
        padding: rem(30) 0 rem(35);
    }
    @include sm {
        border-radius: rem(20);
        gap: rem(10);
    }
    @include xlm {
        padding: rem(40) 0 rem(25);
    }
}

.signInTitle {
    color: var(--clr-primary);
    font-size: rem(60);
    line-height: 1;
    max-width: rem(500);
    @include xl {
        font-size: rem(50);
    }
    @include sm {
        font-size: rem(40);
    }
    @include xlm {
        font-size: rem(35);
    }
}

.registration {
    @extend .signIn;
    padding: rem(25) 0;
    border: rem(2) solid var(--clr-accent);
    @include xlm {
        padding: rem(20) 0 rem(15);
    }
}

.registrationTitle {
    font-size: rem(55);
    line-height: 1;
    color: var(--clr-accent);
    @include xl {
        font-size: rem(45);
    }
    @include sm {
        font-size: rem(35);
    }
    @include xlm {
        font-size: rem(30);
    }
}

.btn {
    --btn-ff: var(--ff-secondary);
    --btn-fw: 400;
    --btn-fz: #{rem(35)} !important;
    padding: 0.1em 1.25em !important;
    border-radius: 1em;

    @include xl {
        --btn-fz: #{rem(30)} !important;
    }

    @include xlm {
        --btn-fz: #{rem(25)} !important;
    }
}

.loader {
    display: block;
    background-color: var(--clr-light50);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 110;

    &.hidden {
        display: none;
    }
}
