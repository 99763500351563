@import "src/app/styles/common";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: var(--clr-background-primary);
    color: var(--clr-light);
    font-family: var(--ff-primary);
}

.row {
    width: 100%;
    height: rem(20);
    background-color: var(--clr-primary);
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(15);
    text-transform: uppercase;
    padding: 0 #{rem(10)};
    position: relative;

    @include xlm {
        gap: rem(10);
    }
}

.center {
    text-align: center;
}

.image {
    --size: #{rem(180)};

    width: var(--size);
    @include xlm {
        --size: #{rem(120)};
    }
}

.text {
    --fz: #{rem(40)};
    font-size: var(--fz);
    font-weight: 600;
    @include sm {
        --fz: #{rem(30)};
    }
    @include xlm {
        --fz: #{rem(25)};
    }
    @include xm {
        --fz: #{rem(20)};
    }
}

.curtainText {
    --fz: #{rem(34)};
    font-size: var(--fz);
    font-weight: 600;
    @include sm {
        --fz: #{rem(26)};
    }
    @include xlm {
        --fz: #{rem(21)};
    }
    @include xm {
        --fz: #{rem(19)};
    }
}

.divider {
    --divider-height: #{rem(10)};

    position: relative;
    padding-bottom: var(--divider-height);

    &::after {
        content: "";

        width: 100%;
        height: var(--divider-height);

        background-color: var(--clr-accent);
        border-radius: 0.2em;

        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.btn {
    --btn-fz: #{rem(25)};
    margin-top: #{rem(15)};

    @include xlm {
        --btn-fz: #{rem(20)};
        margin-top: #{rem(10)};
    }
}
